import React, { Component, useEffect, useState  } from 'react';
import StepWizard from "react-step-wizard";
import "./styles.css";
import { Collapse, Card, CardGroup, ListGroup } from "react-bootstrap";
import * as Message from '../../components/shared/Message';
import * as _planoPagamentoService from '../../service/planoPagamento-service.js';
import * as _movimentacoesService from '../../service/movimentacoes-service.js';
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import LoadingAnimation from "../../components/LoadingAnimation/LoadigAnimation";
import csatList from "../../utils/csatList";


class PlanoDePagamento extends Component {
    constructor(props) {
        super(props);
        this.state = this.getState();
        this.setData = this.setData.bind(this);
        this.setRequest = this.setRequest.bind(this);
    }

    getState() {
        return {
            isLoading: false,
            isLoadingParcela: false,
            isLoadingSalvar: false,
            idMatricula: this.props.Matricula.idMatricula,
            request: {
                matriculaId: this.props.Matricula.idMatricula,
                servicoRegular: {
                    qntParcela: 0
                },
                servicosObrigatorios: [],
                servicosComplementares: [],
            },
            data: null,
            collapsed: false,
            permiteMovimentarAvista: true
        }
    }

    async componentDidMount() {
        this.setState({ isLoading: true});
        await this.buscarValores(this.state.request);
        var idUnidade = sessionStorage.getItem("@SECRETARIA/idUnidade");
        var permiteMovimentarAvista = await _movimentacoesService.PermiteMovimentarAvista(this.state.idMatricula, idUnidade, 284360004, 0);
        this.setState({ permiteMovimentarAvista: permiteMovimentarAvista.value });
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.Matricula !== this.props.Matricula) {
            this.setState(this.getState(), () => {
                this.componentDidMount();
            })
        }
    }

    async buscarValores(request) {
        this.setState({ isLoadingParcela: true });
        var result = await _planoPagamentoService.ValoresReparcelamentoServicos(this.state.idMatricula, request);
        
        var data = null;
        var obrigatorios = [];
        if (result != null && result.statusCode == 0) {
            data = {...result.value};
            obrigatorios = data.obrigatorios.map((x) => {
                return {
                    idServico: x.servicoId,
                    qntParcela: parseInt(x.parcelamentoNovo),
                };
            });
            request.servicosObrigatorios = obrigatorios;
            request.servicoRegular.idServico = data.servicoPrincipal.servicoId
        } else {
            Message.ExibeListMensagem(result.messages, true);
        }
        this.setState({ isLoading: false, data: data, request: request, isLoadingParcela: false });
    }
    VerificaSeTemPlano(data) {
        var count = 0;

        if (data.obrigatorios.length > 0) {
            var detalhesObrigatorios = data.obrigatorios.find(x => x?.detalhes.length > 0);
            if (detalhesObrigatorios != null) {
                count++;
            }
        }
        if (data.servicoPrincipal?.detalhes?.length > 0) {
            count++;
        }

        if (count == 0) {
            return "Sem opção de parcelamento!";
        } else {
            return "";
        }
    }
    setData() {

    }
    collapse = () => {
        this.setState({ collapsed: !this.state.collapsed });
    };
    setRequest = (value) => {
        this.buscarValores(value);
    };
    changeCalculatorValues = async () => {
        try {
            this.setState({ isLoadingSalvar: true });
            var result = await _planoPagamentoService.ChangeCalculatorValues(this.state.idMatricula, this.state.request);
            if (result != null && result != undefined && result.value != null && result.value != undefined) {
                Message.ExibeMensagem(result.value, "0", true);
            } else {
                Message.ExibeMensagem(result.value, "2", true);
            }
            this.setState({ isLoadingSalvar: false });
        } catch (e) {
            console.error(e)
        } finally {
            if (this.props.handleShowCsat) this.props.handleShowCsat(csatList.trocaPlanoPagamento)
        }
    };
    render() {
        const { isLoading, data, request, collapsed, isLoadingParcela, isLoadingSalvar, permiteMovimentarAvista } = this.state;

        if (!permiteMovimentarAvista) {
            return (
                <div >
                    <div className=" form-group d-flex justify-content-center">
                        <h3 style={{ color: '#0068b2' }}> <strong>AVISO</strong></h3>
                    </div>
                    <div className=" form-group d-flex justify-content-center">
                        <h5 style={{ color: '#0068b2' }}> O Template selecionado não permite movimentações onde o pagamento é Avista.</h5>
                    </div>
                </div>
            );
        }

        if (isLoading) {
            return (<LoadingAnimation/>);
        } else {
            return (
                <div className="modalContainer">
                    <div className="step-1">
                        <p>
                            <strong>Aluno(a): </strong> {this.props.Matricula.estudante_Name}
                        </p>
                        {data != null && !isLoading && (
                            <div>
                                {this.VerificaSeTemPlano(data)}
                                <StepWizard>
                                    {data.servicoPrincipal?.detalhes?.length > 0 && (
                                        <Step
                                            data={data.servicoPrincipal}
                                            setData={this.setData}
                                            name="serviço principal"
                                            property={"servicoRegular"}
                                            collapsed={collapsed}
                                            setCollapsed={this.collapse}
                                            request={request}
                                            setRequest={this.setRequest}
                                            matricula={this.props.Matricula}
                                            changeCalculatorValues={this.changeCalculatorValues}
                                            loading={isLoadingParcela}
                                            loadingSalvar={isLoadingSalvar}
                                        />
                                    )}
                                    {data.obrigatorios.map(
                                        (obrigatorio) =>
                                            obrigatorio?.detalhes?.length > 0 && (
                                                <Step
                                                    data={obrigatorio}
                                                    name={obrigatorio?.nome ?? "serviços obrigatórios"}
                                                    collapsed={collapsed}
                                                    property={"servicosObrigatorios"}
                                                    setCollapsed={this.collapse}
                                                    request={request}
                                                    setRequest={this.setRequest}
                                                    matricula={this.props.Matricula}
                                                    changeCalculatorValues={this.changeCalculatorValues}
                                                    loading={isLoadingParcela}
                                                    loadingSalvar={isLoadingSalvar}
                                                />
                                            )
                                    )}
                                </StepWizard>
                            </div>
                        )}
                    </div>
                </div>
            );
        }
    }
}

function Step({ ...props }) {
  const [parcelas, setParcelas] = useState(0);

  useEffect(() => {
    const anoLetivo = parseInt(props.matricula.anoLetivo_Name);
    if (anoLetivo == new Date().getFullYear()) {
      setParcelas((new Date().getMonth() + 1) * -1 + 12);
    } else if (anoLetivo > new Date().getFullYear()) {
      setParcelas(12);
    }
  }, [props.matricula]);

  const updateRequest = (quantidadeParcelas) => {
    const newRequest = props.request;

    if (Array.isArray(newRequest[props.property])) {
      let servico = {};
      let servicos = newRequest[props.property].filter(
        (x) => x.idServico !== props.data.servicoId
      );
      servico = newRequest[props.property].find(
        (x) => x.idServico == props.data.servicoId
      );

      servico.qntParcela = parseInt(quantidadeParcelas);
      servicos.push(servico);
      props.setRequest({ ...props.request, [props.property]: servicos });
    } else {
      let servico = {};
      servico = newRequest[props.property];
      servico.qntParcela = parseInt(quantidadeParcelas)
      props.setRequest({ ...props.request, [props.property]: servico });
    }
  };

  return (
    <>
      <h2 className="subtitle">
        Abaixo, segue proposta de plano de pagamento de {props.data?.nome}:
      </h2>

      <CardGroup>
        <Card>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <strong> Plano atual </strong>{" "}
            </ListGroup.Item>
            <ListGroup.Item>
              Parcelas: {props.data.parcelamentoAnterior}x
            </ListGroup.Item>
            <ListGroup.Item>
              Valor:R${" "}
              {props.data?.detalhes
                ?.reverse()
                .find((x) => x.valorAnterior > 0)
                ?.valorAnterior?.toFixed(2)}
            </ListGroup.Item>
          </ListGroup>
        </Card>
        <Card>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <strong> Plano novo </strong>
            </ListGroup.Item>
            <ListGroup.Item>
              Parcelas:
                <select
                    style={{ minWidth: "67px" }}
                    onChange={(e) => updateRequest(e.target.value)}
                          >
                    <option value={0}>Á Vista</option>
                </select>
                {props.loading ? (<img
                    className="img-responsive"
                    alt="loading"
                    width="15px"
                    height="15px"
                    src="./img/loading.gif"
                />) :"" }
            </ListGroup.Item>
            <ListGroup.Item>
              Valor:R${" "}
              {props.data?.detalhes
                ?.reverse()
                .find((x) => x.valorNovo > 0)
                ?.valorNovo?.toFixed(2)}
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </CardGroup>
      <label
        className="detalhes"
        style={{ cursor: "pointer" }}
        onClick={() => props.setCollapsed()}
      >
        Ver detalhes
      </label>
      <Collapse in={!props.collapsed}>
        <ul>
          <li>
            <div className="title">Parcela</div>
            <div className="title">Valor Anterior</div>
            <div className="title">Valor Novo</div>
          </li>
          {props.data.detalhes.map((classe) => (
              <li key={classe.numParcela}>
                  <div className="value">{(classe.numParcela == 0 ? 1 : classe.numParcela)}</div>
              <div className="value">
                {classe.valorAnterior > 0
                  ? `R$ ${classe.valorAnterior.toFixed(2)}`
                  : "-"}
              </div>
              <div className="value">
                {classe.valorNovo > 0
                  ? `R$ ${classe.valorNovo.toFixed(2)}`
                  : "-"}
              </div>
            </li>
          ))}
        </ul>
      </Collapse>
      {props.currentStep > 1 && (
              <button className="primary finish" onClick={() => props.previousStep()} >
          Voltar
        </button>
      )}
          {props.currentStep === props.totalSteps ? (
              <button disabled={props.loadingSalvar ? "disabled" : "" }
                  className="primary finish"
                  onClick={() => props.changeCalculatorValues()}
        >
                  {props.loadingSalvar ? "Salvando..." : "Alterar"}
        </button>
      ) : (
                  <button className="primary finish" onClick={() => props.nextStep()} >
          Próximo serviço
        </button>
      )}
    </>
  );
}

export default withUnidadeMatricula(PlanoDePagamento, "Plano de Pagamento", csatList.trocaPlanoPagamento);